import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  POS_SCREEN_PAY,
  POS_SCREEN_SPLIT_PAY,
} from "../../../../../pages/PosScreen/mutation";
import PayForm from "./PayForm";
import { QUERY_TRANSACTION_ACCOUNTS } from "../../../../../pages/queries";
import { toast } from "react-toastify";
import isElectron from "../../../../../helpers/checkElectron";

import Spinner from "../../../../reusable/Spinner";
import {
  modifiedKitchenOrderItemVar,
  orderTypeVar,
  splitPayVar,
} from "../../../../localstate/Cache";
import TabPanel from "../../../../reusable/TabPanel/TabPanel";
import SplitPayForm from "./SplitPayForm";
import { getLocalKey, setLocalKey } from "../../../../../helpers/sessionKey";
import Modal from "../../../../reusable/Modal";
import DeviceNotConnected from "./DeviceNotConnected";
import useHandleToggleSelectedTable from "../../../../../customhooks/useHandleToggleSelectedTable";
import { useNavigate } from "react-router";
import usePosScreenHandleClear from "../../../../../customhooks/usePosScreenHandleClear";

type Props = {
  cartItems?: any;
  openPayPopup?: any;
  setOpenPayPopup?: any;
  enteredNumber?: any;
  setEnteredNumber?: any;
  tableItems?: any;
  customerItems?: any;
  totalAmtCalculations?: any;
  noteItems?: any;
  openPayPrintBillPopup?: any;
  setOpenPayPrintBillPopup?: any;
  paymentOrderId?: any;
  setPaymentOrderId?: any;
  setSelectDiscountType?: any;
  basicSetting?: any;
  receiptData?: any;
  availablePointsCheckboxRef?: boolean;
  selectedCustomer: any;
  RewardPointAmount?: any;
  setSelectSuchargeType?: any;
  chectked?: any;
  setChecked?: any;
};
const Pay = (props: Props) => {
  const {
    cartItems,
    RewardPointAmount,
    tableItems,
    customerItems,
    openPayPopup,
    setOpenPayPopup,
    enteredNumber,
    setEnteredNumber,
    totalAmtCalculations,
    noteItems,
    openPayPrintBillPopup,
    setOpenPayPrintBillPopup,
    paymentOrderId,
    setPaymentOrderId,
    setSelectDiscountType,
    setSelectSuchargeType,
    basicSetting,
    availablePointsCheckboxRef,
    selectedCustomer,
    chectked,
    setChecked,
  } = props;
  const handleDisableSpacebar = (e: KeyboardEvent) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const navigate = useNavigate();
  const [openDeviceNotConnectedPopup, setIsOpenDeviceNotConnectedPopup] =
    useState(false);
  const orderType: any = useReactiveVar(orderTypeVar);
  const splitPay: any = useReactiveVar(splitPayVar);
  const idToModifyKitchenOrder: any = useReactiveVar(
    modifiedKitchenOrderItemVar
  );
  const val = getLocalKey("authorizedBy") || null;
  // const discounAmount: any = (totalAmtCalculations?.netAmount) - (totalAmtCalculations?.totalAmtAfterDiscount)
  //transactionAccountData
  const { data: transactionAccountsData } = useQuery(
    QUERY_TRANSACTION_ACCOUNTS,
    {
      variables: {
        isVisible: true,
      },
    }
  );
  const transactionAccountsList: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) =>
    transactionAccountsList.push({
      id: elem?.node?.pk,
      title: elem?.node?.slug,
      isRequiredTender: elem?.node?.isRequiredTender,
      isDefault: elem?.node?.isDefault,
    })
  );
  useEffect(() => {
    window.addEventListener("keydown", handleDisableSpacebar);
    return () => {
      window.removeEventListener("keydown", handleDisableSpacebar);
    };
  }, []);

  //Customer Lists

  const netDiscount = cartItems?.map((cartItem: any, index: number) => {
    return cartItem.discount ? cartItem.discount : 0;
  });
  const netIndividualDiscount = netDiscount?.reduce(
    (acc: any, num: any) => parseFloat(acc) + parseFloat(num),
    0
  );
  const netTotalDiscount =
    parseFloat(netIndividualDiscount) +
    parseFloat(totalAmtCalculations?.discountAmount);

  // CUSTOMER DROPDOWN FORMAT
  const customerList: any = [];

  const [transactionAccountId, setTransactionAccountId] = useState<any>();

  const [payResponse, setPayResponse] = useState<any>();
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();

  const [createPosPayment, { loading: posPaymentLoading }] =
    useMutation(POS_SCREEN_PAY);
  const [createPosSplitPayment, { loading: posSplitPaymentLoading }] =
    useMutation(POS_SCREEN_SPLIT_PAY);
  const { handleClearPosScreen } = usePosScreenHandleClear();

  const handleElectronKitchenPrint = () => {};
  //Handle Pay
  const handleCreatePay = async (
    e: any,
    id: any,
    slug: string,
    requiredTender: boolean
  ) => {
    e.stopPropagation();
    let orderItems: any = [];
    let orderInfo = {
      kitchenOrder: idToModifyKitchenOrder?.kitchenOrderPk?.pk
        ? idToModifyKitchenOrder?.kitchenOrderPk?.pk
        : "",
      customer: customerItems?.pk ? customerItems.pk : "",
      table: tableItems?.pk ? tableItems.pk : "",
      note: noteItems ? noteItems : "",
      totalPrice: parseFloat(totalAmtCalculations?.netAmount)
        .toFixed(2)
        .toString(),
      discount: netTotalDiscount.toFixed(2) ?? "0.00",
      amountAfterDiscount: parseFloat(
        totalAmtCalculations?.totalAmtAfterDiscount
      )
        .toFixed(2)
        .toString(),
      tax:
        parseFloat(totalAmtCalculations?.taxAmount).toFixed(2).toString() ??
        "0.00",
      amountAfterTax:
        parseFloat(totalAmtCalculations?.totalAmtAfterTax)
          .toFixed(2)
          .toString() ?? "0.00",
      serviceCharge:
        parseFloat(totalAmtCalculations?.serviceChargeAmount)
          .toFixed(2)
          .toString() ?? "0.00",
      grossAmount: parseFloat(totalAmtCalculations?.grossAmount)
        .toFixed(2)
        .toString(),
      paidAmount: !requiredTender
        ? parseFloat(totalAmtCalculations?.grossAmount).toFixed(2).toString()
        : parseFloat(enteredNumber).toFixed(2).toString(),
      account: id,
      orderMethod: orderType?.pk ?? "",
      surCharge:
        parseFloat(totalAmtCalculations?.surChargeAmount).toFixed(2) ?? "0.00",
      authorizedBy: val ?? null,
    };
    cartItems?.map((item: any) => {
      let modItems: any = [];
      if (item?.modifier?.length) {
        item?.modifier?.map((modItem: any) => {
          return modItems.push({
            title: modItem.title,
            product: modItem.pk.toString(),
            quantity: parseFloat(modItem?.quantity).toString(),
            price: parseFloat(modItem?.price ?? 0)
              .toFixed(2)
              .toString(),
            totalPrice: parseFloat(modItem?.total).toFixed(2).toString(),
            discount: modItem?.discount
              ? (modItem?.discount).toFixed(2).toString()
              : "0.00",
            priceAfterDiscount: "0.00",
            tax: parseFloat(modItem?.tax).toFixed(2).toString(),
            grossAmount: modItem?.price * modItem?.quantity,
          });
        });
      }
      orderItems.push({
        title: item.title,
        product: item.pk.toString(),
        quantity: (item?.quantity).toString(),
        price: parseFloat(item?.price).toFixed(2).toString(),
        totalPrice: parseFloat(item?.total).toFixed(2).toString(),
        discount: item?.discount ? (item?.discount).toString() : "0.00",
        surCharge: item?.surcharge ? (item?.surcharge).toString() : "0.00",
        priceAfterDiscount: "0.00",
        tax: parseFloat(item?.tax).toFixed(2).toString() ?? "0.00",
        grossAmount: (
          eval(item?.price).toFixed(2) * eval(item?.quantity).toFixed(2)
        ).toString(),
        modifier: modItems,
      });
    });
    if (cartItems?.length) {
      const response = await createPosPayment({
        variables: {
          orderInfo,
          orderItems,
          redeemPoint: availablePointsCheckboxRef ? RewardPointAmount : null,
        },
      });

      if (response?.data?.createPosOrder?.order?.id) {
        setPaymentOrderId(response?.data?.createPosOrder?.order?.id);
        setOpenPayPrintBillPopup(true);
        setOpenPayPopup(false);
        setEnteredNumber(0);
        setSelectDiscountType();
        setSelectSuchargeType();
        setPayResponse(response);
        handleClearPosScreen();
        if (tableItems?.pk) {
          handleSelectTableToggle(false, tableItems?.pk);
        }
        setLocalKey("authorizedBy", "");
      }
      if (response?.data?.createPosOrder?.success) {
        toast.success(
          response?.data?.createPosOrder?.message ?? "Paid Succesfully"
        );
        setTransactionAccountId("");
        handleClearPosScreen();
        setChecked(false);
      } else {
        // toast.error(errors);
        toast.error(response?.data?.createPosOrder?.message);
        if (
          response?.data?.createPosOrder?.message === "Device is not connected"
        ) {
          setIsOpenDeviceNotConnectedPopup(true);
          handleClearPosScreen();
        } else {
          handleClearPosScreen();
          navigate("/orders");
        }
        setTransactionAccountId("");
      }
    } else {
      toast.info("Partial Payment is not acceptable");
      setEnteredNumber(0);
      setTransactionAccountId("");
    }
  };

  const handleTransactionType = async (
    e: any,
    id: any,
    slug: any,
    requiredTender: any
  ) => {
    setTransactionAccountId(id);
    handleCreatePay(e, id, slug, requiredTender);
  };

  useEffect(() => {
    if (
      payResponse?.data?.createPosOrder?.order?.kitchenOrder === null &&
      isElectron()
    ) {
      handleElectronKitchenPrint();
    }
  }, [payResponse]);

  const enteredAmountList: any = splitPay?.map((item: any) => item?.amount);

  const sumWithInitial = enteredAmountList.reduce(
    (accumulator: string, currentValue: string) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );
  //Handle Split Payment
  const handleSplitPay = async () => {
    let newsplitPayList = splitPay?.map((item: any) => ({
      account: item?.account,
      amount: item?.amount,
      customer: item?.customer,
    }));

    let isAnyAmtPaidThroughCash: any = splitPay?.filter(
      (item: any) => item?.accountTitle?.[0] === "cash"
    );

    let orderItems: any = [];
    let orderInfo = {
      paymentInfo: newsplitPayList,
      kitchenOrder: idToModifyKitchenOrder?.kitchenOrderPk?.pk
        ? idToModifyKitchenOrder?.kitchenOrderPk?.pk
        : "",
      totalPrice: parseFloat(totalAmtCalculations?.netAmount)
        .toFixed(2)
        .toString(),
      discount:
        parseFloat(totalAmtCalculations?.discountAmount)
          .toFixed(2)
          .toString() ?? "0.00",
      amountAfterDiscount: parseFloat(
        totalAmtCalculations?.totalAmtAfterDiscount
      )
        .toFixed(2)
        .toString(),
      tax:
        parseFloat(totalAmtCalculations?.taxAmount).toFixed(2).toString() ??
        "0.00",
      amountAfterTax:
        parseFloat(totalAmtCalculations?.totalAmtAfterTax)
          .toFixed(2)
          .toString() ?? "0.00",
      serviceCharge:
        parseFloat(totalAmtCalculations?.serviceChargeAmount)
          .toFixed(2)
          .toString() ?? "0.00",
      grossAmount: parseFloat(totalAmtCalculations?.grossAmount)
        .toFixed(2)
        .toString(),
      paidAmount: parseFloat(sumWithInitial).toFixed(2).toString(),
      surCharge:
        parseFloat(totalAmtCalculations?.surChargeAmount).toFixed(2) ?? "0.00",
      table: tableItems?.pk ? tableItems.pk : "",
    };
    cartItems.map((item: any) => {
      let modItems: any = [];
      if (item?.modifier?.length) {
        item?.modifier?.map((modItem: any) => {
          return modItems.push({
            title: modItem.title,
            product: modItem.pk.toString(),
            quantity: parseFloat(modItem?.quantity).toString(),
            price: parseFloat(modItem?.price).toFixed(2).toString(),
            totalPrice: parseFloat(modItem?.total).toFixed(2).toString(),
            discount: modItem?.discount
              ? (modItem?.discount).toFixed(2).toString()
              : "0.00",
            priceAfterDiscount: "0.00",
            tax: parseFloat(modItem?.tax).toFixed(2).toString(),
            grossAmount: modItem?.price * modItem?.quantity,
          });
        });
      }
      orderItems.push({
        title: item.title,
        product: item.pk.toString(),
        quantity: (item?.quantity).toString(),
        price: parseFloat(item?.price).toFixed(2).toString(),
        totalPrice: parseFloat(item?.total).toFixed(2).toString(),
        discount: item?.discount
          ? (item?.discount).toFixed(2).toString()
          : "0.00",
        surCharge: item?.surcharge ? (item?.surcharge).toString() : "0.00",
        priceAfterDiscount: "0.00",
        tax: parseFloat(item?.tax).toFixed(2).toString(),
        grossAmount: (
          eval(item?.price).toFixed(2) * eval(item?.quantity).toFixed(2)
        ).toString(),
        modifier: modItems,
      });
    });
    if (cartItems?.length) {
      const response = await createPosSplitPayment({
        variables: {
          orderInfo,
          orderItems,
        },
      });

      if (response?.data?.makeSplitPayment?.success) {
        toast.success(response.data?.makeSplitPayment?.message);
        setPaymentOrderId(response?.data?.makeSplitPayment?.order?.id);
        setOpenPayPrintBillPopup(true);
        setOpenPayPopup(false);
        setEnteredNumber(0);
        setSelectDiscountType();
        handleClearPosScreen();
        setChecked(false);
      } else {
        toast.error(response.data?.makeSplitPayment?.message);
      }
    }
  };

  return (
    <>
      <TabPanel
        tabTitle={["Pay", "Split Pay"]}
        tabPanels={[
          <>
            <PayForm
              openPayPopup={openPayPopup}
              setOpenPayPopup={setOpenPayPopup}
              enteredNumber={enteredNumber}
              setEnteredNumber={setEnteredNumber}
              totalAmtCalculations={totalAmtCalculations}
              paymentOrderId={paymentOrderId}
              openPayPrintBillPopup={openPayPrintBillPopup}
              setOpenPayPrintBillPopup={setOpenPayPrintBillPopup}
              selectedCustomer={selectedCustomer ?? ""}
            />
            {posPaymentLoading ? (
              <Spinner />
            ) : (
              <div className="flex flex-wrap justify-center">
                {transactionAccountsList?.length ? (
                  <span className="flex">
                    {transactionAccountsList?.map((item: any) => (
                      <>
                        {item.isDefault ? (
                          <>
                            <span
                              key={item?.id}
                              onClick={(e) =>
                                handleTransactionType(
                                  e,
                                  item?.id,
                                  item?.title,
                                  item?.isRequiredTender
                                )
                              }
                              className={` ${
                                transactionAccountId === item?.id
                                  ? "bg-[#4338CA] text-white"
                                  : "bg-transparent"
                              }
                        text-xs m-2 font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer`}
                            >
                              {item.isDefault ? item.title : ""}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </span>
                ) : (
                  <span
                    className={`text-xs m-2 font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal bg-transparent `}
                  >
                    "Please create an account first"
                  </span>
                )}
              </div>
            )}
          </>,
          <SplitPayForm
            enteredNumber={enteredNumber}
            setEnteredNumber={setEnteredNumber}
            totalAmtCalculations={totalAmtCalculations}
            selectedCustomer={selectedCustomer ?? ""}
            transactionAccountsList={transactionAccountsList}
            customerData={customerList}
            splitPay={splitPay}
            handleSplitPay={handleSplitPay}
            sumWithInitial={sumWithInitial}
            customerItems={customerItems}
            posSplitPaymentLoading={posSplitPaymentLoading}
          />,
        ]}
      />

      <Modal
        open={openDeviceNotConnectedPopup}
        setOpen={setIsOpenDeviceNotConnectedPopup}
        onCloseModal={() => {
          navigate("/orders");
        }}
        children={<DeviceNotConnected />}
        title={"DibPos"}
      />
    </>
  );
};

export default Pay;
