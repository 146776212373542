import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  POS_SCREEN_ADD_TO_CREDIT,
  POS_SCREEN_CREATE_KITCHEN_ORDER,
  POS_SCREEN_CREATE_MODIFIED_KITCHEN_ORDER,
  POS_SCREEN_HOLD_KITCHEN_ORDER_LIST,
} from "../../../../pages/PosScreen/mutation";
import {
  CURRENT_TERMINAL,
  POS_SCREEN_CUSTOMER_LIST_BY_ID,
  POS_SCREEN_KITCHEN_ORDER_ITEMS,
  POS_SCREEN_PRINTERS_LIST,
} from "../../../../pages/PosScreen/queries";

import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar,
  disableFocusCache,
  disableFocusVar,
  kitchenOrderItemId,
  kitchenOrdreCache,
  modifiedKitchenOrderItemVar,
  noteCache,
  noteVar,
  openTableCache,
  openTableVar,
  orderPrintItemsVar,
  orderPrintItemsVarCache,
  orderTypeCache,
  orderTypeVar,
  permissionTypeVar,
  tableItemsVar,
  totalCalculationVar,
} from "../../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
  GET_DISABLE_FOCUS,
  GET_KITCHEN_CARD_ID,
  GET_NOTE_ITEMS,
  GET_ORDER_PRINT_ITEMS,
  GET_ORDER_TYPE,
} from "../../../localstate/Cart";
import PosScreenCustomerSection from "./PosScreenCustomerSection/PosScreenCustomerSection";
import PosScreenQuickAccessTool from "./PosScreenQuickAccessTool";
import PosScreenTable from "./PosScreenTableSection/PosScreenTable";
import PosScreenTotalSection from "./PosScreenTotalSection";
import { REWARD_SETTING_POINT } from "../../../../pages/queries";
import { getLocalKey, setLocalKey } from "../../../../helpers/sessionKey";
import { QUERY_SETTINGS } from "../../../../pages/Settings/graphql";
import useHandleToggleSelectedTable from "../../../../customhooks/useHandleToggleSelectedTable";
import usePosScreenHandleClear from "../../../../customhooks/usePosScreenHandleClear";

type Props = {};

export default function PosScreenCartSection(props: Props) {
  //local reactive state variables
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const permissionTypes: any[] = useReactiveVar(permissionTypeVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const totalAmtCalculations: any = useReactiveVar(totalCalculationVar);
  const noteItems: any = useReactiveVar(noteVar);
  const idToModifyKitchenOrder: any = useReactiveVar(
    modifiedKitchenOrderItemVar
  );
  const [selectDiscountType, setSelectDiscountType] = useState("discountPer");
  const [selectSuchargeType, setSelectSuchargeType] = useState("surchargePer");
  const [chectked, setChecked] = useState<boolean>(false);

  const [RewardPointAmount, setRewardPointAmount] = useState(null);
  const [printKitchenBill, setPrintKitchenBill] = useState(false);
  const [disableRemotePrint, setDisableRemotePrint] = useState(false);
  const [note, setNote] = useState(false);
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  const { handleClearPosScreen } = usePosScreenHandleClear();

  //Create Add To Credit

  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  let totalAmount = cartItems?.map((item: any) => parseFloat(item?.total));
  let modifiedTotalAmount = totalAmount?.reduce((a: any, b: any) => a + b, 0);

  const [holdCardItemData, setHoldCardItemData] = useState({});
  //Get HoldCard Lists
  const [getHoldCardItem] = useLazyQuery(POS_SCREEN_KITCHEN_ORDER_ITEMS, {
    fetchPolicy: "cache-and-network",
  });

  const getHoldOrderItems = async () => {
    const response = await getHoldCardItem({
      variables: {
        id: kitchenOrdreItemId?.kitchenOrderPk?.id,
      },
    });
    let customerItems = {
      id: response?.data?.kitchenOrder?.customer?.id ?? "",
      name: `${response?.data?.kitchenOrder?.customer?.user?.fullName ?? ""}`,
      pk: response?.data?.kitchenOrder?.customer?.pk ?? "",
    };
    if (response?.data?.kitchenOrder?.customer) {
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar(customerItems),
        },
      });
    }
    if (response?.data?.kitchenOrder?.orderType) {
      const ordeTypeList = {
        pk: response?.data?.kitchenOrder?.orderTypePk,
        title: response?.data?.kitchenOrder?.orderType?.deliveryMethod,
      };
      orderTypeCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          orderType: orderTypeVar(ordeTypeList),
        },
      });
    }
    setHoldCardItemData(response?.data);
  };
  useEffect(() => {
    if (kitchenOrdreItemId?.kitchenOrderPk?.id) {
      getHoldOrderItems();
    }
  }, [kitchenOrdreItemId?.kitchenOrderPk?.id]);

  //Create hold Cart Items
  const [addToHoldCardItem] = useMutation(POS_SCREEN_HOLD_KITCHEN_ORDER_LIST, {
    fetchPolicy: "no-cache",
  });
  //Cancel Order
  const handleCancelCard = async (pk: string) => {
    if (pk) {
      const response = await addToHoldCardItem({
        variables: {
          id: pk,
          orderStatus: "cancel",
          reason: note,
          amount: modifiedTotalAmount,
        },
      });
      if (response?.data?.modifyKitchenOrderStatus?.success) {
        handleClearPosScreen();
        if (tableItems?.pk) {
          handleSelectTableToggle(false, tableItems?.pk);
        }
        toast.success("Successfully Cancel");
      } else {
        toast.success("Some thing went wrongs");
      }
    } else {
      toast.error("Invalid Order");
    }
  };
  //Handle hold card cartItems List
  const handleHoldCard = async () => {
    if (tableItems?.currentKitchenOrder?.pk) {
      const response = await addToHoldCardItem({
        variables: {
          id: tableItems?.currentKitchenOrder?.pk,
          orderStatus: "hold",
        },
      });
      if (response) {
        handleClearPosScreen();
        toast.success("Successfully Hold");
      }
      if (tableItems?.pk) {
        handleSelectTableToggle(false, tableItems?.pk);
      }
    } else {
      const orderList = cartItems;
      let orderItems: any = [];
      if (cartItems) {
        let orderInfo = {
          customer: customerItems?.pk ? customerItems.pk : "",
          table: tableItems?.pk ? tableItems.pk : "",
          note: noteItems ? noteItems : "",
          orderStatus: "hold",
          orderMethod: orderType?.pk ?? "",
        };
        orderList.map((item: any) => {
          let modifierItems: any = [];
          {
            item?.modifier?.map((item: any) => {
              modifierItems.push({
                product: item?.pk,
                quantity: item?.quantity,
                note: item?.note,
              });
            });
          }
          item.isDeleted === false &&
            orderItems.push({
              product: item?.pk,
              quantity: item?.quantity,
              note: item?.note,
              modifier: modifierItems,
            });
        });
        const response = await createKitchenOrder({
          variables: {
            orderInfo: orderInfo,
            orderItems: orderItems,
          },
        });
        if (response) {
          setPrintKitchenBill(true);
          orderPrintItemsVarCache.writeQuery({
            query: GET_ORDER_PRINT_ITEMS,
            data: {
              orderPrintItems: orderPrintItemsVar(
                response?.data?.createKitchenOrder?.kitchenOrder
                  ?.kitchenOrderItems.edges
              ),
            },
          });
          handleClearPosScreen();
          if (tableItems?.pk) {
            handleSelectTableToggle(false, tableItems?.pk);
          }
          toast.success("Successfully Hold");
        }
      }
    }
  };

  const [KitchenCardItemData, setKitchenCardItemData] = useState<any>({});
  const { data: printerListData } = useQuery(POS_SCREEN_PRINTERS_LIST);
  const { data: basicSetting } = useQuery(QUERY_SETTINGS);
  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL);

  const [isLoading, setIsLoading] = useState(false);
  const getFinalItems = (orderPrintItems: any) => {
    let finalItems: any = [];
    printerListData?.printers.edges?.map((value: any, index: number) => {
      let productData = orderPrintItems?.filter((item: any, index: number) =>
        item?.node
          ? item?.node?.product?.printer?.edges?.some((element: any) => {
              if (element?.node?.department === value?.node?.department) {
                return true;
              }
            })
          : item?.node?.product?.printer?.edges?.some((element: any) => {
              if (element?.node?.department === value?.node?.department) {
                return true;
              }
            })
      );
      if (productData?.length)
        finalItems.push({
          location: value?.node?.location,
          department: value?.node?.department,
          type: value?.node?.type,
          products: productData,
        });
    });
    return finalItems;
  };
  const handleElectronKitchenPrint = async (
    orderPrintItems: any,
    kitchenOrderId: any,
    isCreated: boolean = false
  ) => {
    const kitchenOrderDataResponse = await getKitchenCardItem({
      variables: {
        id: kitchenOrderId,
      },
    });
    if (isCreated) {
      setKitchenCardItemData(kitchenOrderDataResponse?.data);
    }
    noteCache.writeQuery({
      query: GET_NOTE_ITEMS,
      data: {
        noteItems: noteVar(""),
      },
    });
    const openPopupAfterRemotePrint: any =
      getLocalKey(`openPopupAfterRemotePrint`) || false;
    if (openPopupAfterRemotePrint === "true") {
      handleClearPosScreen();
      if (tableItems?.pk) {
        handleSelectTableToggle(false, tableItems?.pk);
      }
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar({
            id: "",
            name: "",
            pk: "",
          }),
        },
      });
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(true),
        },
      });
    }
  };

  //Get Kitchen Order Lists
  const [getKitchenCardItem] = useLazyQuery(POS_SCREEN_KITCHEN_ORDER_ITEMS, {
    fetchPolicy: "network-only",
  });

  //Create Kitchen Orders section
  const [createKitchenOrder, { loading: loadingKitchenOrder }] = useMutation(
    POS_SCREEN_CREATE_KITCHEN_ORDER,
    {
      refetchQueries: ["kitchenOrder"],
    }
  );
  //Modified Create Kitchen Orders sectionS
  const [modifiedCreateKitchenOrder, { loading: loadingModifiedKitchenOrder }] =
    useMutation(POS_SCREEN_CREATE_MODIFIED_KITCHEN_ORDER, {
      refetchQueries: ["kitchenOrder"],
    });
  const handleOrderProduct = async () => {
    if (isLoading) {
      return;
    }
    setLocalKey("disableRemotePrint", "true");
    setIsLoading(true);
    if (idToModifyKitchenOrder?.kitchenOrderPk?.pk) {
      let orderItems: any = [];
      let orderInfo = {
        customer: customerItems?.pk ? customerItems.pk : "",
        table: tableItems?.pk ? tableItems.pk : "",
        note: noteItems ? noteItems : "",
        id: idToModifyKitchenOrder?.kitchenOrderPk?.pk,
        orderMethod: orderType?.pk ?? "",
      };
      cartItems?.map((item: any) => {
        let modifierItems: any = [];
        item?.modifier?.map((item: any) => {
          if (item?.quantity > 0) {
            modifierItems.push({
              product: item?.pk,
              quantity: item?.quantity,
              note: item?.note,
            });
          }
        });
        item.isModified &&
          orderItems.push({
            id: item?.idToModifyKitchenOrder ?? "",
            product: item?.pk,
            quantity: item?.quantity,
            note: item?.note,
            modifier: modifierItems,
          });
      });
      const modifiedResponse = await modifiedCreateKitchenOrder({
        variables: {
          orderInfo: orderInfo,
          orderItems: orderItems,
        },
      });
      let editedModifiedList = cartItems.map(
        (item: any, itemIndex: number) => ({
          ...item,
          isModified: false,
        })
      );
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar(editedModifiedList),
        },
      });

      if (orderItems?.length) {
        setLocalKey(
          `${KitchenCardItemData?.kitchenOrder?.id}`,
          JSON.stringify(false)
        );
      }
      if (modifiedResponse) {
        const sortedList =
          modifiedResponse?.data?.topUpKitchenOrder?.modifiedKitchenOrderItems.sort(
            (a: any, b: any) =>
              parseFloat(a?.product?.printOrder?.productOrder) -
              parseFloat(b?.product?.printOrder?.productOrder)
          );
        let kitchenOrderID =
          modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.id;
        const kitchenCardItemId: any = {
          id: modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.id,
          pk: modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.pk,
          customerPk:
            modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.customer
              ?.pk,
          orderTypePk: modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder
            ?.orderMethod
            ? modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder
                ?.orderMethod?.pk
            : " ",
        };
        kitchenOrdreCache.writeQuery({
          query: GET_KITCHEN_CARD_ID,
          data: {
            kitchenOrderItemId: kitchenOrderItemId({ kitchenCardItemId }),
          },
        });
        setIsLoading(false);

        await handleElectronKitchenPrint(sortedList, kitchenOrderID, true);
        toast.success("Top up order sent to kitchen");
      }
    } else {
      const orderList = cartItems;
      let orderItems: any = [];
      if (cartItems) {
        let orderInfo = {
          customer: customerItems?.pk ? customerItems.pk : "",
          table: tableItems?.pk ? tableItems.pk : "",
          note: noteItems ? noteItems : "",
          orderMethod: orderType?.pk ?? "",
        };
        orderList.map((item: any) => {
          let modifierItems: any = [];
          item?.modifier?.map((item: any) => {
            if (item?.quantity > 0) {
              modifierItems.push({
                product: item?.pk,
                quantity: item?.quantity,
                note: item?.note,
              });
            }
          });
          item.isDeleted === false &&
            orderItems.push({
              product: item?.pk,
              quantity: item?.quantity,
              note: item?.note,
              modifier: modifierItems,
            });
        });

        const response = await createKitchenOrder({
          variables: {
            orderInfo: orderInfo,
            orderItems: orderItems,
          },
        });
        if (response) {
          const sortedList =
            response?.data?.createKitchenOrder?.kitchenOrder?.kitchenOrderItems.edges.sort(
              (a: any, b: any) =>
                parseFloat(a?.node?.product?.printOrder?.productOrder) -
                parseFloat(b?.node?.product?.printOrder?.productOrder)
            );
          if (response?.data?.createKitchenOrder?.success) {
            let kitchenOrderId =
              response?.data?.createKitchenOrder?.kitchenOrder?.id;
            const kitchenCardItemId: any = {
              id: response?.data?.createKitchenOrder?.kitchenOrder?.id,
              pk: response?.data?.createKitchenOrder?.kitchenOrder?.pk,
            };
            kitchenOrdreCache.writeQuery({
              query: GET_KITCHEN_CARD_ID,
              data: {
                kitchenOrderItemId: kitchenOrderItemId({ kitchenCardItemId }),
              },
            });
            await handleElectronKitchenPrint(sortedList, kitchenOrderId, true);
            toast.success("Order sent to kitchen");
          } else {
            toast.error("Fail to create remote order");
          }
        }
      }
    }
    setIsLoading(false);
  };

  const handleTableBodyClicked = (e: any) => {
    let editedProductList = cartItems.map((item: any, itemIndex: number) => ({
      ...item,
      isSelected: false,
    }));
    cache.writeQuery({
      query: GET_CART_ITEMS,
      data: {
        cartItems: cartItemsVar(editedProductList),
      },
    });
  };

  const availablePointsCheckboxRef: any = useRef();

  const { data: selectedCustomer } = useQuery(POS_SCREEN_CUSTOMER_LIST_BY_ID, {
    variables: {
      id: customerItems?.id ?? "",
    },
  });
  const { data: rewardPointSettingData } = useQuery(REWARD_SETTING_POINT);
  const handleDisable = () => {
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(true),
      },
    });
  };

  const rewardPointSettingPoint: any =
    parseFloat(rewardPointSettingData?.rewardPointSetting?.amount) /
    parseFloat(rewardPointSettingData?.rewardPointSetting?.point);
  return (
    <>
      <div
        className="flex flex-col h-full mx-0 justify-between"
        onClick={handleDisable}
      >
        <div className="flex-[10%]">
          <PosScreenCustomerSection
            customerItems={customerItems}
            cartItems={cartItems}
            permissionTypes={permissionTypes}
          />
        </div>
        <div
          className="flex-[70%] rounded-lg  overflow-y-auto"
          onClick={(e) => handleTableBodyClicked(e)}
        >
          <div onClick={(e) => e.stopPropagation}>
            <PosScreenTable
              KitchenCardItemData={KitchenCardItemData}
              cartItems={cartItems}
              holdCardItemData={holdCardItemData}
              setDisableRemotePrint={setDisableRemotePrint}
            />
          </div>
        </div>
        <div className="flex-[20%]">
          <hr className="my-2"></hr>
          <p className="text-[12px] text-left py-0">Note*: {noteItems} </p>
          <hr className="my-1"></hr>
          <div>
            <PosScreenTotalSection
              cartItems={cartItems}
              selectedTerminal={currentTerminalData?.currentTerminal?.terminal}
              selectDiscountType={selectDiscountType}
              setSelectDiscountType={setSelectDiscountType}
              customerItems={customerItems}
              availablePointsCheckboxRef={availablePointsCheckboxRef}
              availableRewardPoints={selectedCustomer?.customer?.rewardPoints}
              rewardPointSettingPoint={parseFloat(
                rewardPointSettingPoint
              ).toFixed(2)}
              isRewardPointEnabled={
                rewardPointSettingData?.rewardPointSetting?.isActive
              }
              rewardPointSettingData={rewardPointSettingData}
              setRewardPointAmount={setRewardPointAmount}
              selectSuchargeType={selectSuchargeType}
              chectked={chectked}
              setChecked={setChecked}
            />
          </div>
          <hr className="my-3"></hr>
          <div>
            <PosScreenQuickAccessTool
              cartItems={cartItems}
              selectedTerminal={currentTerminalData?.currentTerminal?.terminal}
              tableItems={tableItems}
              customerItems={customerItems}
              totalAmtCalculations={totalAmtCalculations}
              KitchenCardItemData={KitchenCardItemData}
              handleOrderProduct={handleOrderProduct}
              printKitchenBill={printKitchenBill}
              setPrintKitchenBill={setPrintKitchenBill}
              handleHoldCard={handleHoldCard}
              selectDiscountType={selectDiscountType}
              setSelectSuchargeType={setSelectSuchargeType}
              selectSuchargeType={selectSuchargeType}
              setSelectDiscountType={setSelectDiscountType}
              noteItems={noteItems}
              handleCancelCard={handleCancelCard}
              loadingKitchenOrder={loadingKitchenOrder}
              loadingModifiedKitchenOrder={loadingModifiedKitchenOrder}
              permissionTypes={permissionTypes}
              availablePointsCheckboxRef={
                availablePointsCheckboxRef?.current?.checked ?? false
              }
              selectedCustomer={selectedCustomer}
              disableRemotePrint={disableRemotePrint}
              setDisableRemotePrint={setDisableRemotePrint}
              RewardPointAmount={RewardPointAmount}
              setNote={setNote}
              chectked={chectked}
              setChecked={setChecked}
            />
          </div>
        </div>
      </div>
    </>
  );
}
