import React from 'react'
const ProductCard = (props: any) => {
  const { item } = props
  return (<>
    {item?.node?.product !== null ? <>
      {item?.node?.product?.isActive ?
        <>{
          item?.node?.product?.image ?
            <>
              <img
                src={item?.node?.product?.image}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <span
                title={item?.node?.product?.title}
                className={`${!item?.node?.product?.isModifier ? "" : "text-white"} textWithTwoLine  absolute bottom-0 w-full bg-gray-200 text-xs font-bold text-left px-1`}>
                {item?.node?.product?.title}
              </span>
            </> :
            <>
              <span
                title={item?.node?.product?.title}
                className={`${!item?.node?.product?.isModifier ? "" : "text-white"} textWithTwoLine absolute bottom-[10%] w-full  text-left font-bold px-1`}>
                {item?.node?.product?.title}
              </span>
            </>
        }</> : <>
          {
            item?.node?.product?.image ?
              <div className='w-full h-full'>
                <div className='absolute w-full h-full bg-gray-800 flex justify-center items-center opacity-75 rounded-md shadow-md  text-white text-lg'>
                  Sold Out
                </div>
                <img
                  src={item?.node?.product?.image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                <div className='bg-gray-800 opacity-50 flex justify-center items-center'>

                  <span
                    title={item?.node?.product?.title}
                    className={`${!item?.node?.product?.isModifier ? "" : "text-white"} textWithTwoLine  absolute bottom-0 w-full bg-gray-200 text-xs font-bold text-left px-1`}>
                    {item?.node?.product?.title}
                  </span>
                </div>

              </div> :
              <div className='w-full'>
                <div className='w-full '>
                  <div className='absolute w-full h-full bg-gray-800 flex justify-center items-center opacity-75 rounded-md shadow-md  text-white text-lg'>
                    Sold Out
                  </div>
                  <span
                    title={item?.node?.product?.title}
                    className={`${!item?.node?.product?.isModifier ? "" : "text-white"} textWithTwoLine w-full absolute bottom-[10%] opacity-40 text-left font-bold px-1`}>
                    {item?.node?.product?.title}
                  </span>
                </div>
              </div>
          }
        </>}
    </> : ""}

  </>)
}

export default ProductCard