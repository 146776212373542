import { useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { useNavigate } from "react-router";
import { customerCache, CustomerItemsVar, searchItemsVar } from "../../../components/localstate/Cache";
import { GET_CUSTOMER_ITEMS } from "../../../components/localstate/Cart";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { POS_SCREEN_CUSTOMER_LIST } from "../queries";
import AccessControl from "../../../helpers/accessControl";
import { log } from "console";

const PosScreenCustomerListComponent: any = Loadable({
  loader: () =>
    import(
      "../../../components/PosScreenType/AustraliaPosScreen/PosScreenCartSection/PosScreenCustomerSection/PosScreenCustomerList"
    ),
  loading: DataLoadSpinner,
});
const PosScreenCustomer = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);




  const navigate = useNavigate();


  const { data } = useQuery(POS_SCREEN_CUSTOMER_LIST, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
    },
  });

  const handleNavigateToPos = (value: any, state: String) => {



    let customerItem = {
      id: value?.id,
      name: value?.user?.fullName,
      pk: value?.pk,
    };
    customerCache.writeQuery({
      query: GET_CUSTOMER_ITEMS,
      data: {
        customerItems: CustomerItemsVar(customerItem),
      },
    });
    navigate("/")
  }

  return (

    <div className="w-full">
      <PosScreenCustomerListComponent
        data={data?.customers ?? []}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
        handleNavigateToPos={handleNavigateToPos}
      />
    </div>

  );
};

export default PosScreenCustomer;
